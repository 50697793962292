:root {
  --table-header-row-background: var(--secondary-100, #ebeafe);
  --table-header-cell-divider-color: var(--white, #fff);
  --table-header-row-border-color: var(--gray-150, #e7e7ea);
  --table-row-border-color: var(--gray-100, #ebecf2);
  --table-row-background: var(--white, #fff);
  --table-row-hover-background: var(--hover-color, #f3f3f4);
  --table-row-selected-background: var(--secondary-100, #ebeafe);
  --table-row-selected-border-color: var(--gray-250, #ced0d5);
  --table-text-color: var(--gray-500, #525363);
  --table-text-secondary-color: var(--secondary-500, #392ec9);
  --table-text-accent-color: var(--text-color, #282931);
}

.lf-table {
  display: grid;
  grid-auto-rows: min-content;
  font: 400 14px/16px Roboto;
  color: var(--table-text-color);

  .lf-table-cell {
    position: relative;
    padding: 4px 8px 3px 8px;
    width: 100%;
    overflow: hidden;

    .lf-table-actions {
      display: grid;
      grid-auto-flow: column;
      gap: 12px;
      justify-content: end;
    }
  }

  .lf-table-body-cell {
    display: -webkit-box;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 3.4lh;
    overflow: hidden;
  }

  .lf-cell-value {
    white-space-collapse: break-spaces;
  }

  .lf-table-header-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 13px 22px 13px 8px;
    border-right: 1px solid var(--table-header-cell-divider-color);
  }

  .lf-table-cell-accent {
    font-weight: 500;
    color: var(--table-text-accent-color);
  }

  .lf-table-row {
    align-items: center;
    width: 100%;
    background-color: var(--table-row-background);
    font: 400 14px/16px Roboto;
    overflow: hidden;
  }

  .lf-table-header-row {
    background: var(--table-header-row-background);
    font: 700 12px/14px Roboto;
    text-transform: uppercase;
    height: 36px;
    box-shadow: 0px 1px 0px 0px var(--table-header-row-border-color);
  }

  .lf-table-body-row {
    border-bottom: 1px solid var(--table-row-border-color);
    transition: background-color 50ms;
    height: 52px;

    &:hover {
      --link-text-decoration-color: var(--table-text-secondary-color);
      --link-hover-color: var(--table-text-secondary-color);
      --table-row-background: var(--table-row-hover-background);
    }
  }

  .lf-table-header-cell.select,
  .lf-table-cell.select {
    padding: 10px 12px;
    max-height: unset;
    justify-self: flex-end;
  }

  .lf-table-row {
    .lf-table-cell:not(.select):first-of-type {
      padding-left: 20px;
    }
  }
}

lf-link.ellipsis {
  white-space: unset;
}
