@use './assets/scss/theme/custom-theme.scss' as theme;
@use './assets/scss/color' as *;
@use './assets/scss/mixin' as *;
@use './assets/scss/theme/paginator' as *;
@use './app/modules/spinner/spinner';
@use './assets/scss/popover';
@use './../projects/ui/src/lib/table/styles';

@use '@leafio/styles/base';
@use '@leafio/styles/lf-icons';

@use 'sass:map';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

$assortment-primary: mat.m2-define-palette(theme.$assortment-primary);
$assortment-accent: mat.m2-define-palette(theme.$assortment-accent);
$assortment-warn: mat.m2-define-palette(mat.$m2-red-palette);

$assortment-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $assortment-primary,
      accent: $assortment-accent,
      warn: $assortment-warn,
    ),
  )
);

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.datepicker-theme($assortment-theme);
@include mat.table-theme($assortment-theme);

@include mat.dialog-overrides(
  (
    supporting-text-tracking: 0,
  )
);

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

body {
  --mdc-typography-button-letter-spacing: 0;

  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: map.get($map: $gray, $key: 0);
}

h4 {
  @include font($font-family: 'Montserrat', $weight: 700, $size: 24px, $color: $black);
}

a {
  text-decoration: none;
}

.tooltip-icon {
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  margin-left: 4px;
  width: inherit;
  height: inherit;
  vertical-align: top;
  cursor: help;
  font-size: 12px;
}

// paginator
.page-paginator {
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  @include paginator-sizes(20px, 3px, 26px, 26px, 6px);
  @include paginator-icon(var(--gray-400), 14px);
  @include paginator-page-info-text(Roboto, 12px, 400, var(--gray-400));
  @include paginator-page-number-text(Roboto, 12px, 400, var(--secondary-500), var(--white));
  @include paginator-button-style(
    #e7e7ea,
    3px,
    var(--white),
    linear-gradient(107.81deg, #6c63e8 0%, var(--secondary-500) 98.08%)
  );

  position: sticky;
  bottom: 0;
  background: $white;
  filter: drop-shadow(0px -2px 10px rgba(0, 0, 0, 0.08));
}

// libs
.abm-menu-wrapper {
  @include font($weight: 400, $size: 14px, $color: $basic-black);
}

// errors
.error-message {
  color: var(--system-red, red);
  font-size: 10px;
}

.highlight-text {
  display: inline;
  background: #dae8fc;
}

// select overlay
.matrix-product-history-select {
  --select-option-font-size: 14px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

app-submenu.rounded {
  border-radius: 6px;
}

.lf-ordinary-btn {
  &.lf-btn.ghost:not([disabled]):active,
  &.lf-btn.lf-btn_ghost:not([disabled]):active {
    box-shadow: unset;
  }

  &.lf-btn.ghost:not([disabled]):hover,
  &.lf-btn.ghost:not([disabled]):focus-visible,
  &.lf-btn.lf-btn_ghost:not([disabled]):hover,
  &.lf-btn.lf-btn_ghost:not([disabled]):focus-visible {
    background-color: transparent;
  }
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.drag-divider {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 2px;
  background-color: var(--lf-brand-500);
  z-index: 99;
}

//dialog
.mat-mdc-dialog-container .mat-mdc-dialog-title {
  --mdc-dialog-subhead-color: $basic-black;
  --mdc-dialog-subhead-font: Montserrat;
  --mdc-dialog-subhead-line-height: 24px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 700;
  --mdc-dialog-subhead-tracking: 0;
}
