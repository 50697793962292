.lf-modal-window {
  &[popover] {
    width: 800px;
    inset: 0;
    padding: 0;
    opacity: 0;
    border: unset;
    transition: all 0.2s ease-in allow-discrete;
  }

  &[popover]:popover-open {
    opacity: 1;
  }

  &[popover]::backdrop {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.35);
    transition: opacity 0.2s ease-in allow-discrete;
  }

  &[popover]:popover-open::backdrop {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

.lf-menu-window {
  inset: 0;
  transition: opacity 200ms ease-in;

  &:popover-open {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  &::backdrop {
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.01);
    transition: opacity 0.2s ease-in allow-discrete;
  }

  &:popover-open::backdrop {
    opacity: 1;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.01);
  }
}

@starting-style {
  .lf-modal-window:popover-open {
    opacity: 0;
  }

  .lf-modal-window:popover-open::backdrop {
    opacity: 0;
  }

  .lf-menu-window:popover-open {
    opacity: 0;
  }

  .lf-menu-window:popover-open::backdrop {
    opacity: 0;
  }
}
