.mat-calendar-body-selected {
  background: var(--system-gradient);
  background-color: unset;
  color: var(--white);
}

mat-calendar {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--white);
  --mat-datepicker-calendar-date-text-color: var(--secondary-500);
  --mat-datepicker-calendar-period-button-text-color: var(--secondary-500);
  --mat-datepicker-calendar-period-button-icon-color: var(--secondary-500);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--secondary-500);
  --mat-datepicker-calendar-header-text-size: 14px;

  .mat-calendar-table-header-divider {
    display: none;
  }

  .mat-calendar-controls {
    margin: 0 calc(4.7142857143% - 16px) 5%;
  }

  .mat-calendar-content tr[role='row'] td.mat-calendar-body-label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-calendar-body-cell.mat-calendar-body-selected {
    background: var(--system-gradient);
    color: var(--white);
  }

  .mat-calendar-body-cell-content {
    font: 500 14px/15px Roboto;
    border-radius: 6px;

    &.mat-calendar-body-today {
      background-color: var(--secondary-100) !important;
      border-color: var(--secondary-500) !important;
    }
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--secondary-200);
  }
}

// snack bar
mat-snack-bar-container.mdc-snackbar {
  --mdc-snackbar-container-color: var(--white);
  --mdc-snackbar-container-shape: 8px;
  --mdc-snackbar-supporting-text-color: var(--gray-500);

  &.mat-mdc-snack-bar-container {
    margin-right: 16px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    font-size: 14px;
    color: var(--black);
  }

  .mat-mdc-snack-bar-label {
    max-width: 474px;
  }
}

// dialog
.custom-create-dialog,
.file-uploader-dialog {
  --mat-dialog-container-max-width: 100vw;

  mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

// form field
mat-form-field {
  &.mat-mdc-form-field {
    font-size: inherit;
    justify-content: center;

    .mdc-text-field--no-label {
      .mat-mdc-form-field-infix {
        padding: 0;
      }
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    display: flex;
    align-items: center;
    background-color: var(--white);
    padding: 0;
    border-radius: 4px;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--no-label {
    .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;
      padding: 0 !important;
      width: unset;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: 38px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

// tabs
.mat-mdc-tab,
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--secondary-500);
  --mat-tab-header-label-text-letter-spacing: 0;

  .mat-mdc-tab-label-container {
    .mat-mdc-tab-labels {
      .mat-mdc-tab {
        flex-grow: 0;
      }

      .mdc-tab {
        font-weight: 500;
        font-size: 18px;

        &__content {
          .mdc-tab__text-label {
            color: var(--gray-300);
          }
        }

        &--active {
          .mdc-tab__text-label {
            color: var(--secondary-500);
          }
        }
      }
    }
  }

  .mat-mdc-tab:hover .mdc-tab__ripple::before {
    opacity: 0;
  }
}
.mat-mdc-dialog-actions {
  justify-content: flex-end !important;
  border-top: 1px solid var(--border) !important;
  width: 100%;
  bottom: 0;
  right: 0;
}
