@use 'sass:meta';
@use 'sass:map';

$white: #fff;
$black: #191a1b;
$basic-black: #282931;
$border: #dedfe7;
$blue: #1a6eed;
$orange: #ff9900;
$hover: #f3f3f4;

$system: (
  'red': #e81421,
  'red-semi': #ff3d47,
  'red-light': #fdeced,
  'green': #3dcc36,
  'green-light': #f5fcf5,
  'yellow': #ffad0e,
);

$secondary: (
  100: #ebeafe,
  200: #ccc9f8,
  300: #9994e3,
  400: #6c63e8,
  500: #392ec9,
);

$gray: (
  0: #f8f9fd,
  100: #ebecf2,
  150: #e7e7ea,
  200: #dedfe7,
  250: #ced0d5,
  300: #acaeb9,
  400: #868897,
  500: #525363,
  600: #545a6b,
);

:root {
  --text-color: #{$basic-black};
  --white: #{$white};
  --black: #{$black};
  --blue: #{$blue};
  --border: #{$border};
  --hover-color: #{$hover};
  --system-gradient: linear-gradient(107.81deg, var(--secondary-400) 0%, var(--secondary-500) 98.08%);

  @each $key in map.keys($gray) {
    $color: map.get($gray, $key);
    --gray-#{$key}: #{$color};
  }

  @each $key in map.keys($secondary) {
    $color: map.get($secondary, $key);
    --secondary-#{$key}: #{$color};
  }

  @each $key in map.keys($system) {
    $color: map.get($system, $key);
    --system-#{$key}: #{$color};
  }

  --abm-tab-list-border-color: var(--secondary-500);
  --abm-tab-list-border-radius: 4px;
  --abm-tab-list-bg-color: var(--white);

  --abm-chips-select-indicator-width: 14px;
  --abm-chips-font-size: 13px;
  --abm-chips-selected-color: var(--secondary-500);
  --abm-chips-font-family: Roboto;
  --abm-chips-font-color: var(--secondary-500);
  --abm-chips-selected-font-color: var(--white);

  --link-hover-color: var(--gray-600);
  --link-text-decoration-color: transparent;

  --checkbox-bg: var(--gray-0);

  --secondary-500-rgb: 57, 46, 201;
}
