:host {
  display: none;
}

.abm-menu-wrapper {
  width: 100%;
  max-width: 320px;
  .abm-menu {
    display: flex;
    flex-flow: column nowrap;
    gap: 1px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    cursor: pointer;
    font: inherit;
    overflow: hidden;
  }
}
