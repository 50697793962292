@mixin paginator-sizes(
  $block-margin: 16px,
  $pages-margin: 4px,
  $page-button-height: 20px,
  $page-button-width: 20px,
  $page-button-padding: 4px
) {
  --info-block-margin: #{$block-margin};
  --pages-block-margin: #{$pages-margin};
  --page-button-height: #{$page-button-height};
  --page-button-width: #{$page-button-width};
  --page-button-padding: #{$page-button-padding};
}

@mixin paginator-icon($color: #eeeeee, $size: 20px) {
  --paginator-icon-color: #{$color};
  --paginator-icon-size: #{$size};
}

@mixin paginator-page-info-text(
  $font-family: Roboto system-ui,
  $font-size: 1em,
  $font-weight: normal,
  $color: #000000
) {
  --paginator-page-info-font-family: #{$font-family};
  --paginator-page-info-font-size: #{$font-size};
  --paginator-page-info-font-weight: #{$font-weight};
  --paginator-page-info-color: #{$color};
}

@mixin paginator-page-number-text(
  $font-family: Roboto system-ui,
  $font-size: 1em,
  $font-weight: normal,
  $color: #000000,
  $active-color: #947565
) {
  --paginator-page-number-font-family: #{$font-family};
  --paginator-page-number-font-size: #{$font-size};
  --paginator-page-number-font-weight: #{$font-weight};
  --paginator-page-number-color: #{$color};
  --paginator-page-number-active-color: #{$active-color};
}

@mixin paginator-button-style(
  $border-color: #eeeeee,
  $border-radius: 4px,
  $background-color: #ffffff,
  $background-active-color: #5ba14d
) {
  --paginator-button-style-border-color: #{$border-color};
  --paginator-button-style-border-radius: #{$border-radius};
  --paginator-button-style-background-color: #{$background-color};
  --paginator-button-style-background-active-color: #{$background-active-color};
}
