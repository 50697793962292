:host {
  display: block;
}

.abm-menu-item {
  padding: 14px 12px;
  background-color: var(--white);
  box-shadow: 0px 1px 0px var(--gray-100);
  border-radius: inherit;
  line-height: 1.2;

  &:hover {
    background: var(--hover-color);
  }
}
