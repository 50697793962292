#spinner-svg-animated-el {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#spinner-text-node {
  display: none;
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--gray-400);
  }

  &.default {
    background: var(--white, #ffffff);
    font:
      700 20px/24px 'Montserrat',
      'Roboto';

    .spinner-container {
      flex-flow: column;
    }
  }

  &.square {
    background: transparent;

    .spinner-container {
      padding: 22px;
      background: var(--white, #ffffff);
      box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }
  }

  &.small {
    position: static;
    background: transparent;

    .spinner-container {
      flex-flow: row nowrap;
      font-size: 14px;
      gap: 12px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
